<template>
  <b-modal
    id="editPreOrder-pre-order"
    :titleHtml="titelModal"
    hide-footer
    size="xl"
    scrollable
  >
    <div>
      <b-row>
        <b-col cols="9">
          <div v-if="preOrder.orderWarning">
            <b-alert show variant="warning">
              <i class="fa fa-exclamation-triangle text-warning"></i>
              <strong class="text-dark">
                {{ preOrder.orderWarningName }}</strong
              ></b-alert
            >
          </div>
          <b-row>
            <b-col md="4">
              <p class="text-dark font-size-h5 font-weight-bold">
                Đơn đặt trước
              </p>
              <b-form-group>
                <label>Cửa hàng:</label>
                <vue-autosuggest
                  :suggestions="optionStores"
                  :get-suggestion-value="(store) => store.item.name"
                  :limit="10"
                  :input-props="{
                    class: 'autosuggest__input',
                    placeholder: 'Tên cửa hàng',
                    disabled: preOrder.orderId,
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  @input="onInputed($event, 'Store')"
                  @selected="(option) => (preOrder.storeId = option.item.id)"
                  v-model="preOrder.storeName"
                >
                  <div slot-scope="{ suggestion }">
                    {{ suggestion.item.name }}
                  </div>
                </vue-autosuggest>
              </b-form-group>
              <b-form-group>
                <label>Trạng thái:</label>
                <b-form-select
                  v-model="preOrder.status"
                  :options="optionStatus"
                  size="sm"
                  class="select-style"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-form-group>
              <b-form-group>
                <label>Ghi chú:</label>
                <b-form-textarea
                  size="sm"
                  class="input-style"
                  v-model="preOrder.notes"
                  :placeholder="'Thêm ghi chú'"
                  :rows="3"
                  :max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <p class="text-dark font-size-h5 font-weight-bold">
                Thông tin khách hàng
              </p>
              <b-form-group>
                <label>Điện thoại: <span class="text-danger">*</span></label>
                <vue-autosuggest
                  :suggestions="optionCustomers"
                  :get-suggestion-value="(customer) => customer.item.phoneNo"
                  :limit="10"
                  :input-props="{
                    class: 'autosuggest__input',
                    placeholder: 'SĐT Khách hàng',
                    disabled: preOrder.orderId,
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  @input="onInputed($event, 'Customer')"
                  v-model="preOrder.customerMobile"
                  @selected="onSelected($event, 'Customer')"
                >
                  <div slot-scope="{ suggestion }">
                    <span class="mr-1">{{ suggestion.item.phoneNo }}</span>
                    <span
                      class="text-primary"
                      v-if="
                        suggestion.item.fullName &&
                        suggestion.item.fullName.trim().length > 0
                      "
                    >
                      - {{ suggestion.item.fullName }}
                    </span>
                  </div>
                </vue-autosuggest>
              </b-form-group>
              <b-form-group>
                <label
                  >Họ tên:<span class="text-primary">{{
                    preOrder.totalPointText
                  }}</span></label
                >
                <b-form-input
                  class="input-style"
                  size="sm"
                  v-model="preOrder.customerName"
                  placeholder="Họ tên khách hàng"
                  :disabled="preOrder.orderId ? true : false"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Email:</label>
                <b-form-input
                  class="input-style"
                  size="sm"
                  v-model="preOrder.customerEmail"
                  placeholder="Email khách hàng"
                  :disabled="preOrder.orderId ? true : false"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Địa chỉ:</label>
                <b-form-textarea
                  size="sm"
                  class="input-style"
                  v-model="preOrder.customerAddress"
                  :placeholder="'Thêm địa chỉ khách hàng'"
                  :rows="3"
                  :max-rows="6"
                  :disabled="preOrder.orderId ? true : false"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <div class="d-flex">
                <p class="text-dark font-size-h5 font-weight-bold mr-1">
                  Thông tin thanh toán
                </p>
                <span v-if="preOrder.partnerPaymentStatus === 2">
                  <b-badge variant="secondary">Chưa thanh toán</b-badge>
                </span>
                <span v-else-if="preOrder.partnerPaymentStatus === 1">
                  <b-badge variant="success">Đã thanh toán</b-badge>
                </span>
              </div>

              <b-form-group>
                <label class="mr-1">Trả trước:</label>

                <b-form-input
                  class="input-style text-right"
                  size="sm"
                  v-mask.raw="mask"
                  v-model="preOrder.installMoneyAmount"
                  placeholder="Trả góp"
                  :disabled="htmlIsValid(preOrder, 'installment')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Dịch vụ trả góp:</label>
                <vue-autosuggest
                  :suggestions="optionInstallment"
                  v-model="preOrder.installmentMoneyAccountName"
                  :limit="100"
                  :input-props="{
                    class: 'autosuggest__input',
                    placeholder: 'Dịch vụ trả góp',
                    disabled: htmlIsValid(preOrder, 'installment'),
                  }"
                  @input="onInputed($event, 'Installment')"
                  @selected="
                    (option) =>
                      (preOrder.installMoneyAccountId = option.item.id)
                  "
                >
                  <div slot-scope="{ suggestion }">
                    {{ suggestion.item.name }}
                  </div>
                </vue-autosuggest>
              </b-form-group>
              <b-form-group>
                <label>Mã hợp đồng:</label>
                <b-form-input
                  size="sm"
                  v-model="preOrder.installMoneyCode"
                  placeholder="Nhập mã"
                  :disabled="htmlIsValid(preOrder, 'installment')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mb-5">
            <b-row>
              <b-col>
                <div class="d-flex mb-1">
                  <span class="mr-2"> Máy khách muốn sở hữu:</span>
                  <div style="font-size: 1.1rem">
                    <b class="mr-2">{{ formatProductName(preOrder) }}</b>
                    <span class="text-success">{{
                      convertPrice(preOrder.productPrice)
                    }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col cols="3" class="pr-0">
                    <b-form-select
                      v-model="preOrder.searchProductType"
                      :options="[
                        {
                          id: 1,
                          name: 'Tìm sản phẩm',
                        },
                        {
                          id: 2,
                          name: 'Tìm sản phẩm imei',
                        },
                      ]"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled
                      class="select-style"
                    ></b-form-select>
                  </b-col>
                  <b-col cols="9" class="pl-0">
                    <vue-autosuggest
                      v-model="preOrder.productNameSearch"
                      :suggestions="optionProducts"
                      :get-suggestion-value="
                        (product) => product.item.productName
                      "
                      :limit="10"
                      :input-props="{
                        class: 'autosuggest__input',
                        placeholder: 'Tìm kiếm tên, mã , barcode sản phẩm',
                        disabled: preOrder.orderId,
                      }"
                      @selected="onSelected($event, 'Product')"
                      @input="onInputed($event, 'Product')"
                      :should-render-suggestions="
                        (size, loading) => size >= 0 && !loading
                      "
                    >
                      <template slot-scope="{ suggestion }">
                        <div>
                          <span class="text-primary">
                            ({{ convertPrice(suggestion.item.sellingPrice) }})
                          </span>
                          (<span
                            v-if="suggestion.item.quantityInStock > 0"
                            class="text-success"
                          >
                            Tồn TCB:
                            {{ suggestion.item.quantityInStock }}
                          </span>
                          <span v-else class="text-danger">
                            Tồn TCB:
                            {{ suggestion.item.quantityInStock }}
                          </span>
                          )
                          {{ suggestion.item.productName }}
                          <span
                            v-if="
                              suggestion.item.imeiCode &&
                              suggestion.item.imeiCode.trim().length > 0
                            "
                            class="text-warning"
                          >
                            - IMEI:
                            {{ suggestion.item.imeiCode }}
                          </span>
                        </div>
                      </template>
                    </vue-autosuggest>
                  </b-col>

                  <b-col>
                    <b-table
                      :items="preOrdersProduct"
                      :fields="preOrdersProductFields"
                      :small="true"
                      :busy="onLoading"
                      responsive
                      bordered
                      hover
                      class="mt-5"
                    >
                      <template #cell(actions)="data">
                        <i
                          style="font-size: 1rem; color: #d33; cursor: pointer"
                          class="flaticon2-rubbish-bin-delete-button"
                          @click="removePreOrdersProduct(data.item)"
                        ></i>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4" class="d-flex"> </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <p class="text-dark font-size-h5 font-weight-bold">
                Thông tin khác
              </p>
              <div v-if="preOrder.customerCurrentPhone">
                <label> Điện thoại khách đang sở hữu: </label>
                <p
                  class="mt-1"
                  v-html="'&bull; ' + preOrder.customerCurrentPhone"
                ></p>
              </div>
              <div v-if="preOrder.wantUpgrade !== null">
                <label> Khách muốn lên đời: </label>
                <p
                  class="mt-1"
                  v-html="
                    preOrder.wantUpgrade === true
                      ? '&bull; Có nhu cầu'
                      : '&bull; Không có nhu cầu'
                  "
                ></p>
              </div>
              <div v-for="(item, index) in preOrder.attributes" :key="index">
                <div v-if="item.value">
                  <label>
                    <span v-if="!item.text"> {{ item.key }}:</span>
                    <span v-else> {{ item.text }}:</span>
                  </label>
                  <div class="mt-1" v-html="'&bull; ' + item.value"></div>
                </div>
              </div>
              <b-form-group v-if="preOrder.id">
                <template slot="label">&nbsp;</template>
                <b-card no-body>
                  <b-card-header
                    style="
                      padding: 12px 12px 5px 12px;
                      background-color: #f1f1f1;
                    "
                  >
                    <div class="d-flex">
                      <i
                        class="fas fa-history mr-2"
                        style="padding-top: 0.5px"
                      ></i>
                      <h6>Lịch sử</h6>
                    </div>
                  </b-card-header>
                  <b-card-body class="p-1 pb-0">
                    <b-table-simple responsive id="history-table">
                      <b-tbody v-for="item in histories" :key="item.id">
                        <b-tr>
                          <b-td style="width: 45%">
                            <b>{{ item.createdByName }}</b>
                            <span style="font-size: 10px">{{
                              item.createdAt
                            }}</span>
                          </b-td>
                          <b-td style="width: 55%">
                            {{ item.actionName }}
                            <span v-if="item.action === 3">
                              {{ item.newValue.statusName }}
                            </span>
                            <span v-else>
                              <span
                                v-if="
                                  item.originalValue.status !==
                                  item.newValue.status
                                "
                              >
                                {{ item.originalValue.statusName }}
                                ->
                                {{ item.newValue.statusName }}</span
                              >
                            </span>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-card-body>
                </b-card>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-row>
        <b-col>
          <b-button
            style="width: 70px"
            variant="primary"
            size="sm"
            class="mr-3"
            @click="callUpdatePreOrderBy('update')"
            >Lưu</b-button
          >
          <b-button
            style="width: 70px"
            variant="secondary"
            size="sm"
            class="mr-3"
            @click="$bvModal.hide('editPreOrder-pre-order')"
            >Hủy</b-button
          >
          <b-button
            v-if="!preOrder.orderId"
            style="fontweight: 600; width: max-content"
            variant="warning"
            size="sm"
            @click="callUpdatePreOrderBy('moveToOrder')"
            >Chuyển qua đơn hàng</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<style lang="scss">
#editPreOrder-pre-order {
  .modal-header {
    padding: 0.8rem 1.75rem;
  }
  #history-table tbody tr td > span {
    display: block;
  }
  #history-table tbody tr td > b {
    width: max-content;
  }
  .alert-warning {
    color: #855700;
    background-color: #ffeecc;
    border-color: #ffe7b8;
  }
}
</style>
<script>
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import {
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
  currencyMask,
  unMaskPrice,
  removeAccents,
} from '../../../utils/common';
import { cloneDeep, assign, isEmpty, debounce, map } from 'lodash';
import { PREORDER_STATUS, TIME_TRIGGER } from '../../../utils/constants';

export default {
  props: ['params', 'stores'],
  mixins: [validationMixin],
  data() {
    return {
      mask: currencyMask,
      preOrder: {
        orderId: null,
        storeId: null,
        customerId: null,
        customerMobile: null,
        customerName: null,
        customerEmail: null,
        customerAddress: null,
        formId: null,
        status: 1,
        notes: null,
        productId: null,
        productName: null,
        productNameSearch: null,
        searchProductType: 1,
        installMoneyAccountId: null,
        installMoneyCode: null,
        installMoneyAmount: 0,
        partnerPaymentStatus: null,
        attributes: [],
        products: [],
        totalPointText: null,
        id: null,
      },
      preOrdersProductFields: [
        {
          key: 'productNameSystem',
          label: 'Tên sản phẩm',
          thClass: 'text-center',
        },
        {
          key: 'barCode',
          label: 'Barcode',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'sellingPrice',
          label: 'Số tiền',
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'actions',
          label: '',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      preOrdersProduct: [],
      optionStatus: PREORDER_STATUS.filter(
        (status) => status.checked === false
      ),
      onLoading: false,
      filteredStores: [],
      titelModal: null,
      filteredInstallments: [],
      filteredProducts: [],
      filteredCustomers: [],
      histories: [],
    };
  },
  computed: {
    optionStores() {
      return [{ data: cloneDeep(this.filteredStores) }];
    },
    optionCustomers() {
      return [{ data: cloneDeep(this.filteredCustomers) }];
    },
    optionProducts() {
      return [{ data: cloneDeep(this.filteredProducts) }];
    },
    optionInstallment() {
      return [{ data: cloneDeep(this.filteredInstallments) }];
    },
  },
  created() {
    this.fetchInstallments();
  },
  watch: {
    $props: {
      handler() {
        this.initialParams();
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    convertPrice,
    checkPermissions,
    htmlIsValid(item, action) {
      let check = false;
      switch (action) {
        case 'installment': {
          if (item.orderId && !item.orderWarning) {
            check = true;
          }
          break;
        }
      }
      return check;
    },
    formatProductName(data) {
      return [data.productName, data.size, data.color]
        .filter((item) => item)
        .join(' - ');
    },

    initialParams() {
      this.preOrder.productNameSearch = '';
      this.histories = [];
      if (!isEmpty(this.params)) {
        const preOrder = assign(this.preOrder, this.params);

        this.preOrder = preOrder;

        this.titelModal = 'Không có chương trình';
        if (this.preOrder.formName && this.preOrder.formName.length) {
          this.titelModal = `<div>
              <span class='text-primary d-block' style='font-size: 1.1rem'>#${this.preOrder.id}</span>
              <span class='mb-0 px-0 d-block'>${this.preOrder.formName}</span>
              </div>
             `;
        }

        this.preOrdersProduct = [];
        if (preOrder.productId && preOrder.product) {
          this.preOrdersProduct.push({
            ...this.preOrder.product,
            productId: preOrder.productId,
          });
        }

        this.getPreOrderHistoryById(this.preOrder.id);
      }

      if (!isEmpty(this.stores)) {
        const stores = this.stores.filter((store) => store.id !== -1);
        this.filteredStores = this.optionStoresTmp = stores;
      }
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Customer': {
          if (!textInput || !textInput.trim().length) {
            return;
          }
          this.debounceInput(textInput, this.searchCustomerAPI);
          break;
        }
        case 'Product': {
          if (!textInput || !textInput.trim().length) {
            return;
          }
          this.debounceInput(textInput, this.searchProductAPI);
          break;
        }
        case 'Store':
          this.debounceInput(textInput, this.searchStore);
          break;
        case 'Installment':
          this.debounceInput(textInput, this.searchInstallment);
          break;
        default:
          break;
      }
    },
    onSelected({ item }, type) {
      switch (type) {
        case 'Customer':
          this.getCustomerInfoById(item.id);
          break;
        case 'Product':
          this.handleSelectedProduct(item);
          break;
        default:
          break;
      }
    },
    removePreOrdersProduct() {
      this.preOrdersProduct = [];
      this.preOrder.productId = null;
    },
    async callUpdatePreOrderBy(type) {
      const response = await this.updatePreOrder();
      if (response) {
        switch (type) {
          case 'moveToOrder': {
            const routeData = this.$router.resolve({
              name: 'add-order',
              query: { fromPreOrder: this.preOrder.id },
            });
            window.open(routeData.href, '_blank');
            break;
          }
          case 'update':
            makeToastSuccess(response.data.message);
            this.getPreOrderHistoryById(this.preOrder.id);
            this.$emit('onRefresh', response.data.data);
            break;
          default:
            break;
        }
      }
    },
    debounceInput: debounce((textInput, fn) => {
      fn(textInput);
    }, TIME_TRIGGER),
    handleSelectedProduct(product) {
      this.preOrdersProduct = [];
      this.preOrder.productId = product.productId;
      this.preOrdersProduct.push({
        ...product,
        productNameSystem: product.productName,
      });
    },
    getCustomerInfoById(customerId) {
      ApiService.get(`customer/${customerId}`).then((response) => {
        const result = response.data;
        if (result.status) {
          const dataset = result.data;
          this.preOrder.customerPhone = dataset.phoneNo;
          this.preOrder.customerName = dataset.fullName;
          this.preOrder.totalPoint = dataset.totalPoint;
          this.preOrder.totalPointText = ` (Tổng điểm: ${dataset.totalPoint} )`;
        } else {
          makeToastFaile(result.message);
        }
      });
    },
    getPreOrderHistoryById(preOrderId) {
      if (!preOrderId) return;
      ApiService.query(`pre-orders/${preOrderId}/history`).then((response) => {
        if (!response.data.status) {
          makeToastFaile(response.data.message);
          return;
        }
        this.histories = [];
        map(response.data.data, (item) => {
          let data = {
            ...item,
            originalValue: item.originalValue || null,
            newValue: item.newValue || null,
          };
          const items = [data.originalValue, data.newValue];

          map(items, (element) => {
            if (!isEmpty(element) && element.status) {
              element.statusName = null;
              const status = this.optionStatus.find(
                (x) => x.id === element.status
              );
              if (status) element.statusName = status.name;
            }
          });

          this.histories.push(data);
        });
      });
    },
    fetchInstallments: function () {
      ApiService.query('/installments', {
        params: {
          page: 1,
          pageSize: 1000,
        },
      }).then((response) => {
        const installments = response.data.data.result;
        this.filteredInstallments = installments;
        this.optionInstallmentTmp = installments;
      });
    },
    fetchCustomers: function (textSearch) {
      ApiService.query(`customer/get-by-phone`, {
        params: {
          phoneNumber: textSearch,
        },
      }).then((data) => {
        const customers = data.data.data;
        this.filteredCustomers = customers;
      });
    },
    updatePreOrder: function () {
      const preOrderUpdate = cloneDeep(this.preOrder);

      preOrderUpdate.installMoneyAmount = unMaskPrice(
        preOrderUpdate.installMoneyAmount || 0
      );

      return ApiService.put('/pre-orders', preOrderUpdate).catch((error) => {
        this.$nprogress.done();
        if (error.response) {
          makeToastFaile(
            error.response.data ? error.response.data.message : 'Lỗi'
          );
        } else {
          makeToastFaile(error.message || 'Lỗi');
        }
        return null;
      });
    },
    searchCustomerAPI(textInput) {
      this.fetchCustomers(textInput);
    },
    searchProductAPI(textInput) {
      if (this.preOrder.searchProductType === 1) {
        this.fetchProducts(textInput);
      } else {
        this.fetchProductImei(textInput);
      }
    },
    searchStore(textInput) {
      const options = cloneDeep(this.optionStoresTmp);
      if (!textInput || !textInput.trim().length) {
        this.filteredStores = options;
        return;
      }
      const filtered = this.fitlerOptionsBy(
        options,
        textInput,
        ['shortName', 'name'],
        10
      );
      this.filteredStores = filtered;
    },
    searchInstallment(textInput) {
      const options = cloneDeep(this.optionInstallmentTmp);
      if (!textInput || !textInput.trim().length) {
        this.filteredInstallments = options;
        return;
      }
      const filtered = this.fitlerOptionsBy(options, textInput, ['name'], 10);
      this.filteredInstallments = filtered;
    },
    fitlerOptionsBy(items, textInput, props, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameConcatString = (props || []).reduce((result, prop) => {
              return (result += item[prop] + ' ');
            }, '');

            const nameWTUnicode = removeAccents(nameConcatString).toLowerCase();
            const nameInputWTUnicode = removeAccents(textInput).toLowerCase();
            const index = nameWTUnicode.indexOf(nameInputWTUnicode);

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchProductImei: function (textSearch) {
      const storeId = this.preOrder.storeId ? this.preOrder.storeId : 'null';
      ApiService.query(`productSearch/search-by-imei`, {
        params: {
          storeId: storeId,
          imeiCode: textSearch,
        },
      }).then((response) => {
        const products = response.data.data;
        this.filteredProducts = products;
      });
    },
    fetchProducts: function (textSearch) {
      const storeId = this.preOrder.storeId ? this.preOrder.storeId : 'null';
      ApiService.query('productSearch', {
        params: {
          searchProduct: textSearch,
          storeId: storeId,
        },
      }).then((response) => {
        const products = response.data.data;
        this.filteredProducts = products;
      });
    },
  },
};
</script>
